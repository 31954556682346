// extracted by mini-css-extract-plugin
var _1 = "DueDate_button__oLDPh";
var _2 = "DueDate_dueClose__Tk2G9";
var _3 = "DueDate_dueNormal__vnleg";
var _4 = "DueDate_dueOver__Nce-s";
var _5 = "DueDate_wrapper__-uPEo";
var _6 = "DueDate_wrapperCard__JeGBC";
var _7 = "DueDate_wrapperCardModal__J6KDr";
var _8 = "DueDate_wrapperTasksCard__qhtg8";
export { _1 as "button", _2 as "dueClose", _3 as "dueNormal", _4 as "dueOver", _5 as "wrapper", _6 as "wrapperCard", _7 as "wrapperCardModal", _8 as "wrapperTasksCard" }
