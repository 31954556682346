// extracted by mini-css-extract-plugin
var _1 = "Register_alternateActionButtonContainer__DvUF9";
var _2 = "Register_alternateActionText__ddXxC";
var _3 = "Register_buttonsContainer__WwX1q";
var _4 = "Register_checkboxLabel__35ZV2";
var _5 = "Register_checkboxWrapper__t13Ak";
var _6 = "Register_formTitle__xUc0+";
var _7 = "Register_inputLabel__lfTwc";
var _8 = "Register_loginWrapper__CVUgd";
var _9 = "Register_logo__m9O1D";
var _a = "Register_message__BYlzt";
var _b = "Register_onlySsoButtonContainer__-8Oyn";
var _c = "Register_registrationDisabledText__b8GIs";
var _d = "Register_ssoIcon__Lo6BR";
var _e = "Register_submitButton__YOY44";
var _f = "Register_submitButtonIcon__Texqv";
var _10 = "Register_wrapper__kto-m";
export { _1 as "alternateActionButtonContainer", _2 as "alternateActionText", _3 as "buttonsContainer", _4 as "checkboxLabel", _5 as "checkboxWrapper", _6 as "formTitle", _7 as "inputLabel", _8 as "loginWrapper", _9 as "logo", _a as "message", _b as "onlySsoButtonContainer", _c as "registrationDisabledText", _d as "ssoIcon", _e as "submitButton", _f as "submitButtonIcon", _10 as "wrapper" }
