// extracted by mini-css-extract-plugin
var _1 = "BoardAddPopup_checkbox__S73H-";
var _2 = "BoardAddPopup_checkboxText__4qg4T";
var _3 = "BoardAddPopup_checkboxWrapper__dCAFR";
var _4 = "BoardAddPopup_checkboxesWrapper__uBDKv";
var _5 = "BoardAddPopup_dropdownMenu__G23A0";
var _6 = "BoardAddPopup_field__3HdyA";
var _7 = "BoardAddPopup_importButton__sR+QI";
var _8 = "BoardAddPopup_submitButton__W9HlF";
var _9 = "BoardAddPopup_text__yLcqk";
export { _1 as "checkbox", _2 as "checkboxText", _3 as "checkboxWrapper", _4 as "checkboxesWrapper", _5 as "dropdownMenu", _6 as "field", _7 as "importButton", _8 as "submitButton", _9 as "text" }
