// extracted by mini-css-extract-plugin
var _1 = "MainSidebar_boardButton__Kuoe+";
var _2 = "MainSidebar_boardCannotManage__ZUds8";
var _3 = "MainSidebar_boardDraggable__TFiAN";
var _4 = "MainSidebar_collapseIcon__gvxnM";
var _5 = "MainSidebar_collapseIconCollapsed__WHyZV";
var _6 = "MainSidebar_content__q5GLy";
var _7 = "MainSidebar_contentCompact__6ayQT";
var _8 = "MainSidebar_footerButton__RlZe0";
var _9 = "MainSidebar_footerButtonIcon__0+sFH";
var _a = "MainSidebar_githubCannotManage__xwlDq";
var _b = "MainSidebar_githubGreen__ePJws";
var _c = "MainSidebar_githubGrey__dJPvG";
var _d = "MainSidebar_hoverButton__2AT36";
var _e = "MainSidebar_icon__Jn9XB";
var _f = "MainSidebar_reorderBoardsButton__7d1Uv";
var _10 = "MainSidebar_scrollable__UiKNO";
var _11 = "MainSidebar_sidebar__7Qbgr";
var _12 = "MainSidebar_sidebarActive__YRxT4";
var _13 = "MainSidebar_sidebarButton__HKtZu";
var _14 = "MainSidebar_sidebarButtonPadding__aOvHq";
var _15 = "MainSidebar_sidebarCompact__uGOgr";
var _16 = "MainSidebar_sidebarHidden__UbBIR";
var _17 = "MainSidebar_sidebarItem__50Vpz";
var _18 = "MainSidebar_sidebarItemActive__fk1L5";
var _19 = "MainSidebar_sidebarItemBoard__XgNJD";
var _1a = "MainSidebar_sidebarItemInner__058dw";
var _1b = "MainSidebar_sidebarItemProject__SAw4c";
var _1c = "MainSidebar_sidebarTitle__kvgDS";
var _1d = "MainSidebar_sidebarTitleIcon__sZTge";
var _1e = "MainSidebar_toggleSidebarButton__hJIvr";
var _1f = "MainSidebar_wrapper__U7YwV";
export { _1 as "boardButton", _2 as "boardCannotManage", _3 as "boardDraggable", _4 as "collapseIcon", _5 as "collapseIconCollapsed", _6 as "content", _7 as "contentCompact", _8 as "footerButton", _9 as "footerButtonIcon", _a as "githubCannotManage", _b as "githubGreen", _c as "githubGrey", _d as "hoverButton", _e as "icon", _f as "reorderBoardsButton", _10 as "scrollable", _11 as "sidebar", _12 as "sidebarActive", _13 as "sidebarButton", _14 as "sidebarButtonPadding", _15 as "sidebarCompact", _16 as "sidebarHidden", _17 as "sidebarItem", _18 as "sidebarItemActive", _19 as "sidebarItemBoard", _1a as "sidebarItemInner", _1b as "sidebarItemProject", _1c as "sidebarTitle", _1d as "sidebarTitleIcon", _1e as "toggleSidebarButton", _1f as "wrapper" }
