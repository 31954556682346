// extracted by mini-css-extract-plugin
var _1 = "ItemComment_author__J14e1";
var _2 = "ItemComment_button__gcR3T";
var _3 = "ItemComment_buttonIcon__x70w4";
var _4 = "ItemComment_buttons__ux7kw";
var _5 = "ItemComment_content__TKpHb";
var _6 = "ItemComment_date__azm7i";
var _7 = "ItemComment_edited__7nlNj";
var _8 = "ItemComment_popupWrapper__+vHo6";
var _9 = "ItemComment_preview__e5vsy";
var _a = "ItemComment_title__8-+o2";
var _b = "ItemComment_user__S4OVP";
export { _1 as "author", _2 as "button", _3 as "buttonIcon", _4 as "buttons", _5 as "content", _6 as "date", _7 as "edited", _8 as "popupWrapper", _9 as "preview", _a as "title", _b as "user" }
