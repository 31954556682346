// extracted by mini-css-extract-plugin
var _1 = "CardModal_addAttachmentButton__3gsbJ";
var _2 = "CardModal_alignCenter__dS75F";
var _3 = "CardModal_buttonToggle__ryXpA";
var _4 = "CardModal_cursorPointer__t6fNr";
var _5 = "CardModal_descriptionButton__-zAfk";
var _6 = "CardModal_descriptionButtonText__EXASW";
var _7 = "CardModal_descriptionText__r57zS";
var _8 = "CardModal_flexContainer__oei-z";
var _9 = "CardModal_header__+Nab2";
var _a = "CardModal_headerCount__wEHB1";
var _b = "CardModal_headerFirstLine__6f999";
var _c = "CardModal_headerItem__AV22k";
var _d = "CardModal_headerItems__Yj6FN";
var _e = "CardModal_headerListField__f5TBr";
var _f = "CardModal_headerListFieldIcon__ziRgG";
var _10 = "CardModal_headerListFieldWrapper__Qgk2U";
var _11 = "CardModal_headerTitle__uDXT2";
var _12 = "CardModal_headerTitleWrapper__SrbnX";
var _13 = "CardModal_hint__WEYmQ";
var _14 = "CardModal_hr__dgNgI";
var _15 = "CardModal_iconAddButton__ridYu";
var _16 = "CardModal_iconAddButton2__JHoDS";
var _17 = "CardModal_localChangesLoaded__4o8U5";
var _18 = "CardModal_mainContainer__dQo+b";
var _19 = "CardModal_moduleContainer__D5XN2";
var _1a = "CardModal_moduleHeader__b0c7q";
var _1b = "CardModal_moduleIcon__KX6oY";
var _1c = "CardModal_popupWrapper__v8i66";
var _1d = "CardModal_subscribeButton__p2EX5";
var _1e = "CardModal_taskDueDateSummaryWrapper__cQBbr";
var _1f = "CardModal_text__SCB7P";
var _20 = "CardModal_wrapper__sYgdA";
export { _1 as "addAttachmentButton", _2 as "alignCenter", _3 as "buttonToggle", _4 as "cursorPointer", _5 as "descriptionButton", _6 as "descriptionButtonText", _7 as "descriptionText", _8 as "flexContainer", _9 as "header", _a as "headerCount", _b as "headerFirstLine", _c as "headerItem", _d as "headerItems", _e as "headerListField", _f as "headerListFieldIcon", _10 as "headerListFieldWrapper", _11 as "headerTitle", _12 as "headerTitleWrapper", _13 as "hint", _14 as "hr", _15 as "iconAddButton", _16 as "iconAddButton2", _17 as "localChangesLoaded", _18 as "mainContainer", _19 as "moduleContainer", _1a as "moduleHeader", _1b as "moduleIcon", _1c as "popupWrapper", _1d as "subscribeButton", _1e as "taskDueDateSummaryWrapper", _1f as "text", _20 as "wrapper" }
