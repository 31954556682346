// extracted by mini-css-extract-plugin
var _1 = "Icon_icon__8Hcz6";
var _2 = "Icon_size10__IlI9J";
var _3 = "Icon_size12__+2LI5";
var _4 = "Icon_size13__+u0-J";
var _5 = "Icon_size14__xepAE";
var _6 = "Icon_size16__5ZEaT";
var _7 = "Icon_size18__xQB5b";
var _8 = "Icon_size20__eor5X";
var _9 = "Icon_size8__JvA6-";
export { _1 as "icon", _2 as "size10", _3 as "size12", _4 as "size13", _5 as "size14", _6 as "size16", _7 as "size18", _8 as "size20", _9 as "size8" }
