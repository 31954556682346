// extracted by mini-css-extract-plugin
var _1 = "Login_alternateActionButtonContainer__JTqyC";
var _2 = "Login_alternateActionText__nlxT8";
var _3 = "Login_buttonsContainer__LnG7l";
var _4 = "Login_formTitle__xnYyM";
var _5 = "Login_inputLabel__RMy0Y";
var _6 = "Login_loginWrapper__XW1w2";
var _7 = "Login_logo__yguB+";
var _8 = "Login_message__7MtXc";
var _9 = "Login_ssoIcon__+wGZV";
var _a = "Login_submitButton__fMHAq";
var _b = "Login_submitButtonIcon__l30Tq";
var _c = "Login_wrapper__zYzT6";
export { _1 as "alternateActionButtonContainer", _2 as "alternateActionText", _3 as "buttonsContainer", _4 as "formTitle", _5 as "inputLabel", _6 as "loginWrapper", _7 as "logo", _8 as "message", _9 as "ssoIcon", _a as "submitButton", _b as "submitButtonIcon", _c as "wrapper" }
