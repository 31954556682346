// extracted by mini-css-extract-plugin
var _1 = "Board_addListButton__e+3yA";
var _2 = "Board_addListButtonIcon__nfSHM";
var _3 = "Board_addListButtonText__o-FjU";
var _4 = "Board_boardContainer__0Pvtq";
var _5 = "Board_list__VkZpM";
var _6 = "Board_lists__gV2Uz";
var _7 = "Board_mainWrapper__B6nE9";
var _8 = "Board_wrapper__9CuLd";
export { _1 as "addListButton", _2 as "addListButtonIcon", _3 as "addListButtonText", _4 as "boardContainer", _5 as "list", _6 as "lists", _7 as "mainWrapper", _8 as "wrapper" }
