// extracted by mini-css-extract-plugin
var _1 = "User_backgroundAlizarin__PY0Sv";
var _2 = "User_backgroundCarrot__v6hm5";
var _3 = "User_backgroundEmerald__t0l+c";
var _4 = "User_backgroundMidnightBlue__F0nzM";
var _5 = "User_backgroundPeterRiver__JWUd0";
var _6 = "User_backgroundTurquoise__puv02";
var _7 = "User_backgroundWisteria__slK0U";
var _8 = "User_button__CgzK9";
var _9 = "User_initials__9Wp90";
var _a = "User_wrapper__hRMaA";
var _b = "User_wrapperCard__vgSzi";
var _c = "User_wrapperCardTasks__dm1WN";
var _d = "User_wrapperHoverable__+taca";
var _e = "User_wrapperLarge__qyAkY";
var _f = "User_wrapperMassive__RgXWI";
var _10 = "User_wrapperMedium__0p0Sc";
var _11 = "User_wrapperProfile__+-upO";
var _12 = "User_wrapperSmall__9AB0W";
var _13 = "User_wrapperTiny__IEds5";
export { _1 as "backgroundAlizarin", _2 as "backgroundCarrot", _3 as "backgroundEmerald", _4 as "backgroundMidnightBlue", _5 as "backgroundPeterRiver", _6 as "backgroundTurquoise", _7 as "backgroundWisteria", _8 as "button", _9 as "initials", _a as "wrapper", _b as "wrapperCard", _c as "wrapperCardTasks", _d as "wrapperHoverable", _e as "wrapperLarge", _f as "wrapperMassive", _10 as "wrapperMedium", _11 as "wrapperProfile", _12 as "wrapperSmall", _13 as "wrapperTiny" }
