// extracted by mini-css-extract-plugin
var _1 = "globalStyles_controls__lMaLd";
var _2 = "globalStyles_controlsCenter__Haca8";
var _3 = "globalStyles_controlsSpaceBetween__X6dVQ";
var _4 = "globalStyles_cursorDefault__v-ye1";
var _5 = "globalStyles_cursorGrab__qH9D3";
var _6 = "globalStyles_cursorPointer__0VMk1";
var _7 = "globalStyles_scrollableX__jlqxE";
var _8 = "globalStyles_scrollableXY__aNGJY";
var _9 = "globalStyles_scrollableY__5swd+";
export { _1 as "controls", _2 as "controlsCenter", _3 as "controlsSpaceBetween", _4 as "cursorDefault", _5 as "cursorGrab", _6 as "cursorPointer", _7 as "scrollableX", _8 as "scrollableXY", _9 as "scrollableY" }
