// extracted by mini-css-extract-plugin
var _1 = "Boards_addButton__3ahyl";
var _2 = "Boards_addButtonIcon__sJZFP";
var _3 = "Boards_board__BdAEC";
var _4 = "Boards_boardTitle__r7vw0";
var _5 = "Boards_boardWrapper__-1NiQ";
var _6 = "Boards_boardsWrapper__3tg2v";
var _7 = "Boards_header__OfsJr";
var _8 = "Boards_headerButton__iRoB+";
var _9 = "Boards_headerButtonGroup__-DE7e";
var _a = "Boards_headerDetails__+4zn4";
var _b = "Boards_headerText__j8U0i";
var _c = "Boards_notification__1PNTe";
var _d = "Boards_wrapper__xuo3b";
export { _1 as "addButton", _2 as "addButtonIcon", _3 as "board", _4 as "boardTitle", _5 as "boardWrapper", _6 as "boardsWrapper", _7 as "header", _8 as "headerButton", _9 as "headerButtonGroup", _a as "headerDetails", _b as "headerText", _c as "notification", _d as "wrapper" }
